//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {resultHandlerMixin} from '@/mixins/resultHandlerMixin'

export default {
  name: 'ChangePasswordLayout',
  mixins: [resultHandlerMixin],
  data() {
    return {
      changePasswordForm: {
        password: '',
        confirmPassword: '',
      },
      newPasswordRules: [
        (v) => !!v || 'New password is required',
        (v) =>
          (!!v && v.length >= 5) ||
          'The password must be at least 5 characters long',
      ],
    }
  },
  methods: {
    async changePassword() {
      if (!this.$refs.changePasswordForm.validate()) {
        return
      }
      const form = {
        Username: this.$auth.user.username,
        CheckForRequest: false,
        NewPassword: this.changePasswordForm.password,
        ConfirmPassword: this.changePasswordForm.confirmPassword,
      }
      const result = await this.$axios.$post(
        'User/set-new-password-request',
        form
      )

      this.handleApiResult(result.apiMessages)
      if (result.apiMessages.hasErrors) {
        return
      }

      this.$emit('close')
    },
  },
}
