export const resultHandlerMixin = {
  methods: {
    handleApiResult(result) {
      if (result && result.hasErrors === false) {
        const [message] = result.successMessages
        this.$toast.success(message)
      } else {
        const [error] = result.serverErrors
        this.$toast.error(error)
      }
    },
  },
}
