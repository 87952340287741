//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChangePasswordLayout from '@/frontend/components/common/change-password-layout'
export default {
  name: 'FrontendLayout',
  components: {ChangePasswordLayout},
  data() {
    return {
      forceCloseDialog: false,
    }
  },
  head() {
    return this.$nuxtI18nHead({
      addSeoAttributes: true,
    })
  },
  computed: {
    showChangePasswordDialogAfterReset() {
      return (
        !this.forceCloseDialog &&
        this.$auth.loggedIn &&
        this.$auth.user.mustChangePasswordOnNextLogin
      )
    },
  },
}
